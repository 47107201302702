// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_AT_RULE_IMPORT_0___ = require("-!../../node_modules/css-loader/dist/cjs.js!minireset.css/minireset.css");
exports = ___CSS_LOADER_API_IMPORT___(false);
exports.i(___CSS_LOADER_AT_RULE_IMPORT_0___);
exports.push([module.id, "@import url(https://fonts.googleapis.com/css?family=Libre+Franklin:500&display=swap);"]);
exports.push([module.id, "@import url(https://fonts.googleapis.com/css?family=Merriweather|Montserrat:900&display=swap);"]);
// Module
exports.push([module.id, "\n", ""]);
// Exports
module.exports = exports;
